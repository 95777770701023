let $body,
    $hamburger,
    $menuMobile,
    $header,
    $headerHeight,
    $heroSlider,
    $carouselSlider,
    $logoSlider,
    $contentContainer,
    $social,
    $tableOfContents,
    $tabs,
    $tabsContent,
    $toTopButton,
    $toggleAuthorPopup,
    $authorPopup,
    $copyrightBlock,
    $copyrightContent,
    $queryString,
    $readMoreButton,
    $readMoreContainer,
    $observationContainer,
    $addToCalendarSelect,
    $videoBlock,
    $programParts,
    $headerSlider,
    $headerSliderThumbnails,
    $pictureSlider,
    $pictureSliderOverlay,
    $closeOverlay,
    $audioElem,
    $audioPlayer,
    $ad,
    $videoPlayer,
    accordion;


$(document).ready(function ($) {
    console.log('%cLinku Novio', "font-size: 2em; font-family: sans-serif; color: white; background-color:#18aaff; padding:4px 8px; border-radius: 4px");

    $body = $('body');
    $hamburger = $('.js-menu-toggle');
    $carouselSlider = $('.js-carousel-slider');
    $header = $('.js-header');
    $menuMobile = $('.js-menu-mobile');
    $heroSlider = $('.js-hero-slider');
    $logoSlider = $('.js-logo-slider');
    $videoBlock = $('.wp-block-video');
    $contentContainer = $('.js-item-content');
    $social = $('.js-social-share');
    $tableOfContents = $('.js-toc');
    $tabs = $('.js-tab');
    $tabsContent = $('.js-tab-content');
    $toTopButton = $('.js-to-top-button');
    $toggleAuthorPopup = $('.js-toggle-author-popup');
    $authorPopup = $('.js-author-popup');
    $copyrightBlock = $('.js-copyright');
    $copyrightContent = $('.js-copyright-content');
    $headerHeight = $header.outerHeight();
    $readMoreButton = $('.js-read-more-button');
    $readMoreContainer = $('.js-read-more-container');
    $observationContainer = $('.js-observation-container');
    $addToCalendarSelect = $('.js-add-to-calendar');
    $programParts = $('.js-program-part');
    $videoPlayer = $('.js-video-detail__video-overlay');
    $headerSlider = $('.js-header-slider');
    $headerSliderThumbnails = $('.js-header-slider-thumbnails');
    $pictureSlider = $('.js-picture-slider');
    $pictureSliderOverlay = $('.js-picture-slider-overlay');
    $closeOverlay = $('.js-close-overlay');
    $audioPlayer = $('.js-audio-player');
    $queryString = window.location.search;

    if ($queryString == '?open-flipbook') {
        //delay to make sure everything needed is loaded
        setTimeout(function () {
            $('.open-flipbook').trigger("click")
        }, 1000);
    }

    $hamburger.on('click', function (e) {
        if ($hamburger.hasClass('is-open')) {
            $(this).removeClass('is-open');
            $body.removeClass('menu-is-open');
            $menuMobile.slideToggle();
        } else {
            $(this).addClass('is-open');
            $menuMobile.slideToggle();
            $body.addClass('menu-is-open');
        }
    });

    $observationContainer.each(function () {
        let readMoreButton = $(this).find('.js-read-more-button');
        let readMoreContainer = $(this).find('.js-read-more-container');
        let observationContent = $(this).find('.js-observation-content');
        addClickReadMore(readMoreButton, readMoreContainer, observationContent);
    });
    $programParts.each(function () {
        let readMoreButton = $(this).find('.js-read-more-button');
        let readMoreContainer = $(this).find('.js-read-more-container');
        let partContent = $(this).find('.js-program-content');
        addClickReadMore(readMoreButton, readMoreContainer, partContent);
    });

    $toTopButton.on('click', function () {
        $("html, body").animate({scrollTop: 200}, {duration: 500, queue: false});
    })

    $heroSlider.each(function (index, value) {
        var heroSlider = tns({
            container: value,
            items: 1,
            mouseDrag: true,
            speed: 600,
            nav: true,
            navPosition: "bottom",
            autoplayButton: false,
            autoplayPosition: 'bottom',
            autoplayText: ['<i class="fal fa-play"></i>', '<i class="fal fa-pause"></i>'],
            controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
            controls: true,
            autoplay: true,
            autoHeight: false,
        });
        setLabelsAfterSliderRendered(heroSlider);

    });

    var headerSlider;
    var thumbnailSlider

    $headerSlider.each(function (index, value) {
        headerSlider = tns({
            container: value,
            items: 1,
            mouseDrag: true,
            speed: 600,
            nav: true,
            navPosition: "bottom",
            autoplayButton: false,
            mode: "gallery",
            autoplayText: ['', ''],
            controlsText: ['', ''],
            controls: false,
            autoplay: false,
            autoHeight: true,
            center: true,
            loop: true,
            autoplayHoverPause: true,
            navContainer: '.js-header-slider-thumbnails'
        });
        setLabelsAfterSliderRendered(headerSlider);

    });

    $headerSliderThumbnails.each(function (index, value) {
        var thumbnailSlider = tns({
            container: value,
            items: 5,
            mouseDrag: true,
            speed: 600,
            nav: false,
            autoplayButton: false,
            autoplayText: ['', ''],
            controlsText: ['', ''],
            controls: true,
            autoplay: false,
            autoHeight: false,
            autoplayHoverPause: true,
            loop: false,
            fixedWidth: 85,
            gutter: 12,
        })
        setLabelsAfterSliderRendered(thumbnailSlider);

        headerSlider.events.on('indexChanged', function (evt) {
            const indexToGoTo = evt.index > headerSlider.getInfo().slideCount ? 0 : evt.index - 1;
            thumbnailSlider.goTo(indexToGoTo);
        });

    });

    var pictureSlider;

    $pictureSlider.each(function (index, value) {
        pictureSlider = tns({
            container: value,
            items: 1,
            center: true,
            controlsText: ['<i class="fal fa-arrow-left"></i>', '<i class="fal fa-arrow-right"></i>'],
            nav: false,
            mouseDrag: true,
            loop: false,
            onInit: hideSlideItems,
            gutter: 24,
            navContainer: '.js-picture-slider-overlay',
            responsive: {
                991: {
                    gutter: 0
                }
            }

        });
        setLabelsAfterSliderRendered(pictureSlider);

        // bind function to event
        pictureSlider.events.on('indexChanged', hideSlideItems);
    });

    function hideSlideItems (info, eventName) {

        var containerId = info.container.id;
        var $sliderItems = $('#' + containerId + ' .tns-item');
        var $activeSlide = $('.tns-slide-active');

        $sliderItems.addClass('is-hidden');
        $activeSlide.removeClass('is-hidden');
        $activeSlide.prev().removeClass('is-hidden');
        $activeSlide.next().removeClass('is-hidden');
    }



    $pictureSliderOverlay.each(function (index, value) {
        var pictureSliderOverlay = tns({
            container: value,
            items: 1,
            center: true,
            controlsText: ['<i class="fal fa-arrow-left"></i>', '<i class="fal fa-arrow-right"></i>'],
            nav: false,
            mouseDrag: true,
            loop: false,
        });
        setLabelsAfterSliderRendered(pictureSliderOverlay);

        pictureSlider.events.on('indexChanged', function (evt) {
            const indexToGoTo = evt.index > pictureSlider.getInfo().slideCount ? 0 : evt.index - 2;
            pictureSliderOverlay.goTo(evt.index);
        });
    });

    $closeOverlay.on('click', function (){
        $('.b-picture-slider__overlay-background').removeClass('is-open');
    });

    $('.js-slide-container').on('click', function (){
        if ( $(window).width() > 767 ){
            $('.b-picture-slider__overlay-background').addClass('is-open');
        }
    });

    $carouselSlider.each(function (index, value) {
        var carouselSlider = tns({
            container: value,
            items: 1,
            mouseDrag: true,
            speed: 1000,
            nav: true,
            navPosition: "bottom",
            autoplayButton: false,
            autoplayButtonOutput: false,
            autoplayPosition: 'bottom',
            controlsText: ['<i class="fal fa-arrow-left"></i>', '<i class="fal fa-arrow-right"></i>'],
            // controls: true,
            autoplay: false,
            autoHeight: false,
            center: true,
            responsive: {
                992: {
                    fixedWidth: 650,
                    items: 3
                },
                1200: {
                    fixedWidth: 792
                }
            }
        });
        setLabelsAfterSliderRendered(carouselSlider);

    });

    $copyrightBlock.each(function () {
        let captionHeight = 0;
        if($(this).find('figcaption').length !== 0){
            captionHeight = $(this).find('figcaption').outerHeight() + 6;
        }
        console.log("captionheight ", captionHeight);
        $(this).find('.js-copyright-content').css('bottom', captionHeight);
    })


    $logoSlider.each(function (index, value) {

        // $.when(
        var logoSlider = tns({
            container: value,
            items: 2,
            mouseDrag: false,
            speed: 600,
            controlsContainer: '.js-nav-container',
            controlsText: ['', ''],
            controls: true,
            nav: false,
            autoplay: true,
            autoplayButtonOutput: false,
            autoHeight: true,
            center: true,
            responsive: {
                640: {
                    items: 3
                },
                800: {
                    items: 4
                },
                900: {
                    items: 6
                },
                1200: {
                    items: 8
                }
            }
        });

        setLabelsAfterSliderRendered(logoSlider);


    });

    $videoBlock.each(function () {
      console.log($(this).find('video'));
        if ($(this).find('video').attr('autoplay') !== 'autoplay') {
          $(this).addClass('paused');
        }

        var video = $(this).find('video').get(0);
        var playing = false;

        // video.removeAttribute('controls');
        $(this).click(function () {
            if (playing) {
                video.pause();
                $(this).addClass('paused');

            } else {
                video.play();
                $(this).removeClass('paused');
            }
            playing = !playing;

        });
    });

    $toggleAuthorPopup.on('click', function (e) {
        e.stopPropagation();
        $authorPopup.each(function () {
            if ($(this).hasClass('show')) {
                $(this).fadeOut().removeClass("show");
            }
        });
        $(this).find('.js-author-popup').fadeIn().addClass('show');
    });

    $videoPlayer.on('click', function () {
        $(this).find('.c-video-detail__video').toggleClass('is-visible');
        $(this).toggleClass('is-hidden');

        var videoUrl = $('.c-video-detail__video iframe').attr('src');
        $('.c-video-detail__video iframe').attr('src', videoUrl + "&autoplay=1");
    });


    $body.click(function () {
        $authorPopup.fadeOut().removeClass("show");
    });

    $audioPlayer.on('click', function() {
        let player = $(this).find('audio')[0];
        player.paused ? player.play() : player.pause();
    });


    // Gravityforms upload button
    $('.ginput_container_fileupload').each(function () {
        const currentHtml = $(this).html();
        const newHtml = '<label class="c-form__file-upload"><i class="fas fa-upload"></i> Bestand toevoegen' + currentHtml + '</label><p class="js-upload-text c-form__file-upload-text">Je hebt nog geen bestand toegevoegd.</p>'
        $(this).html(newHtml);
        const currentInput = $(this).find('input[type="file"]');
        currentInput.change(function () {
            $(".js-upload-text").text(this.files[0].name);
        });
    });

    $addToCalendarSelect.change(function () {
        if ($(this).selectedIndex !== 0) {
            window.open($(this).children("option:selected").val(), 'name');
        }
    });
    $('#download-pdf').click(function () {
        console.log('test');
        var fileName = $(this).data('slug');
        var element = document.getElementById('pdfprint');

        var opt = {
            filename: fileName + '.pdf',
            margin: [5,12,15,-28],
            jsPDF: {unit: 'mm', format: 'A4', orientation: 'portrait'},
            pagebreak: {mode: 'avoid-all', avoid: ['img', '.container--small', '.container']}
        };
        html2pdf().set(opt).from(element).toPdf().save();
    });

    initTabs();
    handleTabClicks();

    scrollInContainer($toTopButton);
    scrollInContainer($social);

    accordion = new Accordion(
      '.js-accordion',
      '.js-toggle-accordion',
      '.js-accordion-content',
    );
  
    accordion.initClickHandling();
});

$(window).on('load', function() {

    let ga;
    let height;
    $ad = $('.js-ad');
    $ad.each(function () {
        ga = $(this).find('.js-google-ad');
        height = ga.find('div').height();
        if(!height){
            height = '0';
            $(this).css('display','none');
        }
        if(!$(this).hasClass('js-multiblock')){
            $(this).height(height);
        }
    });
});

$('.js-audio-controls').on('loadedmetadata', function() {
    let duration = $(this)[0].duration;
    let durationString = durationToMinutesSeconds(duration);
    $('.js-audio-duration').text(durationString);
});


function durationToMinutesSeconds(duration){
    var minutes = Math.floor(duration / 60 );
    var seconds = Math.round(duration % 60);
    return (
        seconds === 60 ?
            (minutes+1) + ":00" :
            minutes + ":" + (seconds < 10 ? "0" : "") + seconds
    );
}

function setLabelsAfterSliderRendered(slider) {
    var sliderRenderedTimeout = window.setInterval(function () {
        var sliderRendered = afterSliderRendered(slider);
        if (sliderRendered) {
            window.clearInterval(sliderRenderedTimeout);
        }
    }, 5);
}

function afterSliderRendered(slider) {
    var sliderInfo = slider.getInfo();
    if (sliderInfo.isOn) {
        setSliderButtonLabels(sliderInfo);
        return true;
    } else {
        return false;
    }
}

function setSliderButtonLabels(sliderInfo) {
    $(sliderInfo.nextButton).attr('aria-label', 'slide right');
    $(sliderInfo.prevButton).attr('aria-label', 'slide left');

}


function scrollInContainer($item) {
    if (!isMobile() && !isTablet()) {
        $contentContainer.each(function () {
            var currentContainer = $(this);
            var maxHeight = currentContainer.outerHeight() - 200 - $item.outerHeight();

            $(window).scroll(function () {
                var heightDifference = $(window).scrollTop() - currentContainer.offset().top + $headerHeight;

                if (heightDifference > 0 && heightDifference < maxHeight) {
                    $item.addClass('fix');
                } else {
                    $item.removeClass('fix');
                }

            });
        })
    }
}


function ajax(action, postData, succesCallback, errorCallback) {

    postData = $.extend({}, postData, {action: action});

    $.ajax({
        type: "POST",
        url: linku_ajax_url,
        data: postData,
        dataType: 'json',
        success: function (response) {
            if (response === '0') {
                if (typeof errorCallback === 'function') errorCallback(response);
            } else {
                if (typeof succesCallback === 'function') succesCallback(response);
            }
        },
        error: function (response) {
            if (typeof errorCallback === 'function') {
                if (typeof response.responseJSON === 'object') {
                    errorCallback(response.responseJSON);
                } else if (typeof response.responseText === 'string' && response.responseText !== '') {
                    try {
                        var jsonErr = JSON.parse(response.responseText);
                        errorCallback(jsonErr);
                    } catch (e) {
                        errorCallback({'message': 'general'});
                    }

                } else {
                    errorCallback({'message': 'general'});
                }
            }
        }
    });

}

function initTabs() {
    $tabs.each(function () {
        if ($(this).hasClass('is-active')) {
            var tabName = $(this).data('tab-name');
            var $tabContent = $(`[data-tab-content="${tabName}"]`);
            var $tableOfContent = $tabContent.find('.js-toc');

            if ($tableOfContent.length > 0) {
                monitorActiveTocItem($tabContent[0]);
            }
        }
    });
}

function handleTabClicks() {
    var activeClass = 'is-active';

    $tabs.on('click', function (e) {
        var tabName = $(this).data('tab-name');
        var $tabContent = $(`[data-tab-content="${tabName}"]`);
        var $tableOfContent = $tabContent.find('.js-toc');

        $tableOfContents.removeClass(activeClass);
        if ($tableOfContent.length > 0) {
            $tableOfContent.addClass(activeClass);
            monitorActiveTocItem($tabContent[0]);
        }

        $tabs.removeClass(activeClass);
        $tabsContent.removeClass(activeClass);
        $tabContent.addClass(activeClass);
        $(this).addClass(activeClass);
    });
}


function addClickReadMore($readMoreButton, $readMoreButtonContainer, $readMoreContentContainer) {
    $readMoreButton.click(function () {
        if ($readMoreContentContainer.hasClass('limitShow')) { //open
            $readMoreContentContainer.removeClass('limitShow'); //open
            $readMoreButtonContainer.removeClass('open');
            $readMoreButton.text('Toon minder  -'); //'Lees meer  +
        } else {
            $readMoreContentContainer.addClass('limitShow'); //open
            $readMoreButtonContainer.addClass('open');
            $readMoreButton.text('Lees meer  +'); //Toon minder  -
        }
    })
}

const getAudioDuration = (elem) => {
    return new Promise((resolve) => {
        if(elem.readyState >= 1) {
            resolve(elem.duration);
        }
        $audioElem.on('loadedmetadata', function() {
            resolve(elem.duration);
        });
    });
};

$audioElem = $('.js-audio-controls');
$audioElem.each(function(){
    getAudioDuration($(this)[0]).then((duration) => {
        const durationString = durationToMinutesSeconds(duration);
        $(this).siblings('.js-audio-duration').text(durationString);
    });
});


